import React from "react";
import { Helmet } from "react-helmet";

import Layout from "components/Layout";

import logo from "assets/images/logo.png";

const IndexPage = () => {
  return (
    <Layout pageName="home">
      <Helmet>
        <title>
          PixelCounter : A Specialist Front-End Web Development Studio in
          Brighton
        </title>
        <meta
          name="description"
          content="PixelCounter is a specialist, independent, creative, digital web development studio in Brighton. Focusing on engaging, quirky, and unusual website design and development, search engine optimisation, and social media development."
        />
      </Helmet>
      <img src={logo} alt="PixelCounter Logo" className="logo" />
      <h1 className="title">PixelCounter</h1>
      <p className="text">
        A specialist{" "}
        <a href="https://johnkavanagh.co.uk">front-end web development</a>{" "}
        studio based in Brighton.
      </p>
      <a href="mailto:hello@pixelcounter.com">hello@pixelcounter.com</a>
    </Layout>
  );
};

export default IndexPage;
